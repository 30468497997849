<template>
    <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate"
                     @changeType="changeType" @cancel="show = false"/>
    <CRow v-else>
        <CFormLabel for="staticEmail" class="col-sm-4 col-form-label">
            {{ field.label }}
            <span v-if="field.is_required" style="color: red">*</span>
            <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
        </CFormLabel>
        <div class="col-sm-8">
            <div :class="`${error ? '' : 'h-100'} d-flex align-items-center`">
                <CFormCheck :label="field.placeholder" :checked="form.values" :disabled="disabled" @click="setCheck"/>
            </div>
            <CFormText v-if="error" class="text-error">{{ error }}</CFormText>
        </div>
    </CRow>
</template>

<script>
import mixin from './mixin'

export default {
    mixins: [mixin],
    watch: {
        'field.values': function () {
            this.handleCheckboxValue()
        },
    },
    mounted() {
        this.handleCheckboxValue()
    },
    methods: {
        setCheck() {
            this.form.values = !this.form.values
            this.handleUpdate()
        },
        handleCheckboxValue() {
            const type = typeof this.form.values;

            if (this.form.values === '1') {
                this.form.values = true
                return;
            }

            if (this.form.values === '0') {
                this.form.values = false;
                return;
            }

            if (type !== 'boolean') this.form.values = false;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
