<template>
    <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate"
                     @changeType="changeType" @cancel="show = false"/>
    <CRow v-else>
        <CFormLabel for="staticEmail">
            {{ field.label }}
            <span v-if="field.is_required" style="color: red">*</span>
            <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
        </CFormLabel>
        <div>
            <slot v-if="$slots.input" name="input"/>
            <div v-else style="display: flex; align-items: center; white-space: nowrap;">
                <CFormTextarea v-model="form.values" v-autosize :disabled="disabled" style="background: white"
                               :placeholder="field.placeholder"
                               v-bind="readonly ? { readonly: true, plainText: true } : {}"
                               :style="`border-color: ${validationErrors.values ? 'red' : ''}`"
                               @blur="validateField" @keydown="handleUpdate" @keyup="handleUpdate"/>
                <CFormText v-if="field.sublabel" :style="`margin-left: 5px`">
                    {{ field.sublabel }}
                </CFormText>
            </div>
            <CFormText v-if="validationErrors.values" class="text-error">
                {{ validationErrors.values }}
            </CFormText>
        </div>
    </CRow>
</template>

<script>
import mixin from './mixin'

export default {
    mixins: [mixin],
    watch: {
        'field.values': function () {
            if (typeof this.form.values !== 'string') this.form.values = ''
        },
    },
    mounted() {
        if (typeof this.form.values !== 'string') this.form.values = ''
    },
}
</script>
