<template>
    <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate"
                     @changeType="changeType" @cancel="show = false"/>
    <CRow v-else>
        <CFormLabel for="staticEmail" class="col-sm-4 col-form-label">
            {{ field.label }}
            <span v-if="field.is_required" style="color: red">*</span>
            <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
        </CFormLabel>
        <div class="col-sm-8">
            <slot v-if="$slots.input" name="input"/>
            <div v-else style="display: flex; align-items: center; white-space: nowrap;">
                <CInputDate :key="error" :model-value="form.values" :type="'clear'" :disabled="disabled"
                            :placeholder="field.placeholder" :error="validationErrors.values"
                            @update:modelValue="changeValue" @touchField="validateField"/>
                <CFormText v-if="validationErrors.values" class="text-error">
                    {{ field.sublabel }}
                </CFormText>
            </div>
        </div>
    </CRow>
</template>

<script>
import CInputDate from '@/components/CInputDate'
import mixin from './mixin'

export default {
    components: {CInputDate},
    mixins: [mixin],
    watch: {
        'field.values': function () {
            if (!['string', 'object'].includes(typeof this.form.values)) this.form.values = ''
        },
    },
    mounted() {
        this.setRules()
        if (!['string', 'object'].includes(typeof this.form.values)) this.form.values = ''
    },
    methods: {
        changeValue(value) {
            this.form.values = value ? value.toISOString() : ''
            this.validateField()
            this.handleUpdate()
        },
    },
}
</script>
