<template>
    <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate"
                     @changeType="changeType" @cancel="show = false"/>
    <CRow v-else>
        <CFormLabel for="staticEmail" class="col-sm-4 col-form-label">
            {{ field.label }}
            <span v-if="field.is_required" style="color: red">*</span>
            <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
        </CFormLabel>
        <div class="col-sm-8">
            <slot v-if="$slots.input" name="input"/>
            <div v-else style="display: flex; align-items: center; white-space: nowrap;">
                <CSelectSearch :model-value="form.values"
                               :options="field.options.map((el) => ({ label: el, value: el }))"
                               :error="validationErrors.values" :placeholder="field.placeholder" :search="true"
                               :disabled="disabled" class="w-100" @changeValue="changeValue"
                               @touchField="validateField"/>
                <CFormText v-if="field.label" style="margin-left: 5px">
                    {{ field.sublabel }}
                </CFormText>
            </div>
        </div>
    </CRow>
</template>

<script>
import CSelectSearch from '@/components/CSelectSearch'

import mixin from './mixin'

export default {
    components: {CSelectSearch},
    mixins: [mixin],
    data() {
        return {}
    },
    watch: {
        'field.values': function (val) {
            if (typeof this.form.values !== 'string') this.form.values = ''
        },
    },
    mounted() {
        this.setRules()
        if (typeof this.form.values !== 'string') this.form.values = ''
    },
    methods: {
        changeValue(value) {
            this.form.values = value
            this.validateField()
            this.handleUpdate()
        },
    },
}
</script>
